import { createApp } from "vue"
import vuetify from "../plugins/vuetify.js"
import dynamicFontSizeMixin from "../mixins/dynamicFontSize.js"
import axios from "../plugins/axios.js"
import App from "../app.vue"
import Toast, { POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

// Import global components
import * as global from "../components/global.js"

const app = createApp(App)

// Register global components
for (const [key, value] of Object.entries(global.default)) {
    app.component(key, value)
}

app.use(vuetify)
app.config.devtools = true // Vue Devtools
app.mixin(dynamicFontSizeMixin)
app.use(axios)

app.use(Toast, {
    position: POSITION.TOP_CENTER
})

app.mount("#app")
