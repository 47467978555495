import { useDisplay } from 'vuetify'

export default {
    computed: {
        dynamicFontSize() {
            const display = useDisplay()
            return (optionalValue = 0) => {
                if (display.xs.value) {
                    return `${10 + optionalValue}px`; // Smallest font size for extra small screens
                } else if (display.sm.value) {
                    return `${12 + optionalValue}px`; // Smaller font size for small screens
                } else if (display.md.value) {
                    return `${14 + optionalValue}px`;  // Medium font size for medium screens
                } else if (display.lg.value) {
                    return `${14 + optionalValue}px`;  // Larger font size for large screens
                } else if (display.xl.value) {
                    return `${14 + optionalValue}px`;  // Largest font size for extra large screens
                }
                return `${14 + optionalValue}px`;  // Default font size for unknown or other cases
            }
        }
    }
}
