import 'vuetify/styles'
import { createVuetify } from "vuetify";
import * as components from 'vuetify/components'
import * as labsComponents from 'vuetify/labs/components'
import * as directives from 'vuetify/directives'
import "@mdi/font/css/materialdesignicons.min.css";

const light = {
    dark: false,
    colors: {
      background: '#E6F2EA',       // Lighter shade for better contrast
      surface: '#44BD51',          // Greenish tint for money impression
      primary: '#1E73FD',          // Kept the same
      secondary: '#D1D93B',        // Slightly adjusted for harmony
      error: '#E53935',            // Slightly darker for better visibility
      info: '#1E88E5',             // Adjusted to match primary tone
      success: '#43A047',          // Slightly darker green
      warning: '#FFB300',          // Slightly darker amber
      'on-background': '#212121',  // Darker for better text contrast
      'on-surface': '#000000',     // Kept black for maximum contrast
    },
  };
  
  const dark = {
    dark: true,
    colors: {
      background: '#1E282F',       // Slightly lighter for better contrast
      surface: '#256335',          // Dark greenish tint for money impression
      primary: '#1E73FD',          // Same as light theme for consistency
      secondary: '#D1D93B',        // Same as light theme for consistency
      error: '#EF5350',            // Slightly lighter red
      info: '#42A5F5',             // Lighter blue for visibility
      success: '#66BB6A',          // Lighter green for visibility
      warning: '#FFA726',          // Lighter amber for visibility
      'on-background': '#FFFFFF',  // White text on dark background
      'on-surface': '#FFFFFF',     // White text on dark surfaces
    },
  };
  

export default createVuetify({
    components: {
        ...components,
        ...labsComponents,
        directives,
    },
    theme: {
        defaultTheme: "dark",
        themes: {
            light,
            dark
        },
    },
    icons: {
        defaultSet: 'mdi', // This is already the default value - only for display purposes
    },
});
